.bubble {
    margin-top: 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s, box-shadow 0.3s;
    user-select: none; /* Prevent text selection on double click */
    margin-left: 10px;
}

.bubble .switch {
  position: relative;
  display: inline-block;
  min-width: 34px;
  height: 20px;
  top: 2px;
  margin-left: 15px;
  margin-right: 15px;
}

.bubble .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.bubble .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.bubble .slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 1px;
  bottom: 1px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

.bubble input:checked + .slider {
  background-color: #e21833;
}

.bubble input:checked + .slider:before {
  transform: translateX(14px);
}

.bubble input:focus + .slider {
  box-shadow: 0 0 1px #e21833;
}

.bubble:hover, .bubble:focus {
    box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Enhances visual feedback */
}
