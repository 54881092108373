.sample-questions {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
  }
  
  .light-app .sample-questions .question-bubble {
    background-color: white;
    color: #1a1a1a;
    border: 1px solid #2a2a2a;
  }
  
  .dark-app .sample-questions .question-bubble {
    background-color: #1a1a1a;
    color: white;
    border: 1px solid #ccc;
  }
  
  .question-bubble {
    flex-grow: 1;
    padding: 10px;
    border-radius: 20px;
    font-size: 16px;
    text-align: center;
    margin: 0 5px;
    cursor: pointer;
  }
  

  .light-app .sample-questions .question-bubble:hover {
    background-color: #ccc;
  }
  
  .dark-app .sample-questions .question-bubble:hover {
    background-color: #2a2a2a;
  }
  