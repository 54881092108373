.header {
    padding: 10px 20px;
    text-align: center;
    width: 100%;  /* Adjusted to take full width of the parent */
    font-weight: normal;
    font-size: x-large;
    margin-bottom: 0;
}

.light-app .header {
    background-color: white;
    color: #1a1a1a;
}

.dark-app .header {
    background-color: #1a1a1a;
    color: white;
}

.header h1 {
    margin: 0;
    padding: 0;
}

.header p {
    margin: 5px 0;
}

