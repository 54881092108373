.sidebar {
    /* padding: 20px; */
    height: 100%;
    width: min(15vw, 200px);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1500;
    position: fixed;
    top: 0;
    left: 0;
}

.light-app .sidebar {
    background-color: #e6e6e6;
    color: #1a1a1a;
}
.dark-app .sidebar {
    background-color: #282828;
    color: white;
}

.sidebar h2 {
  margin-bottom: 0px;
}

.light-app .sidebar h2 {
    color: #e21833;
}

.dark-app .sidebar h2 {
  color: #ff4d4d;
}

.sidebar h3 {
  margin-bottom: 5px;
  margin-top: 30px;
}

.sidebar nav ul {
    list-style-type: none;
    padding: 0;
}

.sidebar nav ul li {
    margin-bottom: 10px;
}

.light-app .sidebar nav ul li a {
    color: #333;
    text-decoration: none;
}

.light-app .sidebar nav ul li a:hover {
    color: #333;
}

.dark-app .sidebar nav ul li a {
    color: white;
    text-decoration: none;
}

.dark-app .sidebar nav ul li a:hover {
    color: #f0f0f0;
}

.theme-switch-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Basic styling for the toggle switch */
.theme-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide the default checkbox input */
.theme-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.theme-switch-wrapper .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.theme-switch-wrapper .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
  background-image: url('../../public/legoyoda.png');
  background-size: cover;
  background-position: center;
}

.theme-switch-wrapper input:checked + .slider {
  background-color: #404040;
}

.theme-switch-wrapper input:focus + .slider {
  box-shadow: 0 0 1px #404040;
}

.theme-switch-wrapper input:checked + .slider:before {
  background-position: center;
  transform: translateX(26px);
  background-image: url('../../public/legodarth.jpg');
}

/* Rounded sliders */
.theme-switch-wrapper .slider.round {
  border-radius: 34px;
}

.theme-switch-wrapper .slider.round:before {
  border-radius: 50%;
}

.chatbot-link-bubble {
  display: inline-block;
  padding: 10px 15px;
  margin: 5px;
  background-color: #e21833; /* Green background */
  color: white; /* White text */
  border-radius: 20px;
  text-decoration: none; /* Removes underline from links */
  transition: background-color 0.3s, box-shadow 0.3s;
}

.chatbot-link-bubble:hover, .chatbot-link-bubble:focus {
  background-color: #c1152d; /* Darker green on hover */
  box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Subtle shadow for feedback */
  outline: none; /* Remove focus outline */
}

.sidebar .dropdown {
  width: 100%; /* Make dropdown full width of the sidebar */
  padding: 10px;
  border-radius: 20px;
  font-size: 16px;
  background-color: #ffffff; /* Default to light theme */
  color: #1a1a1a; /* Text color for light theme */
  border: 1px solid #2a2a2a; /* Border color for light theme */
  margin-top: 5px;
  cursor: pointer;
}

.dark-app .sidebar .dropdown {
  background-color: #1a1a1a; /* Background for dark theme */
  color: #ffffff; /* Text color for dark theme */
  border: 1px solid #ccc; /* Border color for dark theme */
}

.sidebar .dropdown:hover {
  background-color: #ccc; /* Light theme hover background */
}

.dark-app .sidebar .dropdown:hover {
  background-color: #2a2a2a; /* Dark theme hover background */
}
