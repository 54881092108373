.root, .dark, .light {
  height: 100vh;
  overflow: hidden;
}


.App {
  text-align: center;
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.chat-container {
  flex-grow: 1;
  display: flex;
  /* justify-content: flex-end; */
  flex-direction: column;
  align-items: center;
  margin-left: min(15vw, 200px);
  width: calc(100% - min(15vw, 200px));
  height: 100vh;
  justify-content: center;
  border-radius: 10px;
  overflow: hidden;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 85%;
  max-width: 600px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 80%;
}

.chatbox {
  width: 95%;
  height: calc(100% - 200px);
  overflow-y: auto;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 200px;
  margin-bottom: 185px;
  padding: 10px;
}



.empty-chat {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  color: #bfbfbf;
  font-style: italic;
}

.light-app .empty-chat {
  color: #282828;
}

.dark-app .empty-chat {
  color: #bfbfbf;
}

.message {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 70%;
  word-wrap: break-word;
}

.user-message {
  align-self: flex-end;
}

.light-app .user-message {
  background-color: #ff4d4d;
  color: white;
}

.dark-app .user-message {
  background-color: #ff4d4d;
  color: white;
}

.bot-message {
  align-self: flex-start;
}

.light-app .bot-message {
  background-color: #e6e6e6;
  color: #1a1a1a;
}

.dark-app .bot-message {
  background-color: #404040;
  color: white;
}

.all-inputs {
  position: fixed;
  bottom: 50px;
  padding-left: 5px;
  padding-right: 5px;
  width: 80%;
}

.input-container {
  display: flex;
  width: 100%;
  margin-top: 10px;
}

.input-container input {
  flex-grow: 1;
  padding: 10px;
  border-radius: 20px 0 0 20px;
  font-size: 16px;
}

.light-app .input-container input {
  background-color: white;
  color: #1a1a1a;
  border: 1px solid #2a2a2a;
}

.dark-app .input-container input {
  background-color: #1a1a1a;
  color: white;
  border: 1px solid #ccc;
}

.send-button {
  background-color: #e21833;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 0 20px 20px 0;
  font-size: 16px;
  cursor: pointer;
}

.send-button:hover {
  background-color: #ff1a1a;
}

body.light {
  background-color: white;
  color: #1a1a1a;
}

body.dark {
  background-color: #1a1a1a;
  color: white;
}

.loader {
  border: 6px solid #606060; /* Light grey */
  border-top: 6px solid	#FFFFFF; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

.spinner-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
