.filter-chips {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
}

.filter-chip {
    display: inline-flex;
    align-items: center;
    padding: 6px 12px;
    border-radius: 20px;
    font-size: 16px;
    margin: 5px;
    cursor: pointer;
    border: 1px solid;
}

/* Light theme styles */
.light-app .filter-chip {
    background-color: white;
    color: #1a1a1a;
    border-color: #2a2a2a;
}

/* Dark theme styles */
.dark-app .filter-chip {
    background-color: #1a1a1a;
    color: white;
    border-color: white;
}

/* Hover effects for light theme */
.light-app .filter-chip:hover {
    background-color: #ccc;
}

/* Hover effects for dark theme */
.dark-app .filter-chip:hover {
    background-color: #2a2a2a;
}

.filter-chip button {
    background-color: transparent;
    border: none;
    color: #666;
    cursor: pointer;
    margin-left: 8px;
}

.filter-chip button i.close-icon {
    color: red;
}
